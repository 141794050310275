export class DictWork{

    id:number=null;
    dict_id:number= null;
    dict_email_id:number= null;
    operator:string=null;
    phone:string=null;
    recipient:string=null;
    email:string =null;
    type:string=null;
    status:string=null;
    sent_at:string=null;
    resent_at:string=null;
    answered_at:string=null;
    files_request:any[]=[];

    constructor(id?: number | Object) {
        if (typeof (id) == 'object') {
            this.import(id);
        } else {
            this.id = id;
        }
    }

    import(data) {
        for (var key in data) {
            if (this.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }
}